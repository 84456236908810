<template>
  <div class="k-9sptci">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
    </el-form>
    <el-table :data="dataTable" style="margin-top: 20px" border>
      <template v-for="item in dataList">
        <el-table-column
          v-if="ItemIsShow(item.prop)"
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <perButton
            :perm="`match:stage`"
            @click.native.stop="handleChangeScore(scope.row)"
            >修改积分</perButton
          >
          <perButton
            :perm="`match:stage`"
            @click.native.stop="handleChangeSort(scope.row)"
            >调整排序</perButton
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="'修改积分'"
      :visible.sync="scoreChangeVisible"
      v-if="scoreChangeVisible"
      :close-on-click-modal="false"
      width="400px"
      v-loading="loading"
    >
      <el-form
        ref="scoreForm"
        :model="scoreChangeData"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="赛事：">
          {{ match_types[type_id] }}
        </el-form-item>

        <el-form-item label="门派ID：">
          {{ scoreChangeData.sect_id }}
        </el-form-item>
        <el-form-item label="门派名称：">
          {{ scoreChangeData.sect_name }}
        </el-form-item>
        <el-form-item label="积分：">
          {{ scoreChangeData.score }}
        </el-form-item>
        <el-form-item
          label="修改为："
          prop="new_score"
          label-position="left"
          :rules="[
            {
              required: true,
              message: '请输入积分',
              trigger: ['blur', 'change'],
            },
            {
              validator: scoreInteger,
              message: '请输入大于等于0的整数或2位小数',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-input
            v-model="scoreChangeData.new_score"
            placeholder="请输入积分"
            style="width: 150px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveScore">确定</el-button>
          <el-button @click="scoreChangeVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      :title="'修改同分排序'"
      :visible.sync="sortChangeVisible"
      v-if="sortChangeVisible"
      :close-on-click-modal="false"
      width="400px"
      v-loading="loading"
    >
      <el-form
        ref="sortForm"
        :model="sortChangeData"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="赛事：">
          {{ match_types[type_id] }}
        </el-form-item>

        <el-form-item label="门派ID：">
          {{ sortChangeData.sect_id }}
        </el-form-item>
        <el-form-item label="门派名称：">
          {{ sortChangeData.sect_name }}
        </el-form-item>
        <el-form-item
          label="排序："
          prop="sort"
          label-position="left"
          :rules="[
            {
              required: true,
              message: '请输入排序',
              trigger: ['blur', 'change'],
            },
            {
              validator: sortInteger,
              message: '请输入整数',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-input
            type="number"
            v-model="sortChangeData.sort"
            placeholder="请输入排序"
            maxlength="4"
            style="width: 150px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveSort">确定</el-button>
          <el-button @click="sortChangeVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import perButton from "@/components/perm/perButton";
import { randomStr } from "@/utils/randomStr";
import { isColumnShow } from "@/utils/column";
export default {
  name: "s1_team_rank",
  components: {
    perButton,
  },
  computed: {
    ...mapState("match", ["match_types"]),
    ItemIsShow() {
      return function (val) {
        let type = ["sect_type", "member_mvp_score", "member_svp_score"];
        //在常规赛或者总决赛时隐藏门派类型
        if (
          (this.season_name == "常规赛" ||
            this.season_name == "总决赛" ||
            this.$route.query.match_type == "match_wpl2023") &&
          type.indexOf(val) > -1
        ) {
          return false;
        } else {
          return true;
        }
      };
    },
  },
  props: {
    dataTable: {
      type: Array,
      required: true,
    },
    season_name: {
      type: String,
      required: true,
    },
    // stage: {
    //   type: [String, Number],
    //   required: true,
    // },
    // rankName: {
    //   type: String,
    //   required: true,
    // },
    // season_id: {
    //   type: String,
    //   required: true,
    // },
    // group_id: {
    //   type: String,
    //   required: false,
    // },
    // newGroupInfo: {
    //   type: Array,
    //   required: false,
    // },
  },
  // watch: {
  //   rankName: {
  //     handler: function(newVal, oldVal) {
  //       if (newVal == "team_rank") {
  //         this.new_group_id = this.group_id;
  //         this.handleQuery();
  //       }
  //     },
  //   },
  //   season_id: {
  //     handler: function(newVal, oldVal) {
  //       if (this.rankName == "team_rank") {
  //         //切换赛段将分组置空
  //         this.new_group_id = "";
  //         //如果有分组则显示第一个
  //         if (this.newGroupInfo.length != 0) {
  //           this.new_group_id = this.newGroupInfo[0]._id;
  //         }
  //         this.$emit("update:group_id", this.new_group_id);
  //         //更新页码，初始化
  //         this.pager.pageNum = 1;
  //         this.handleQuery();
  //       }
  //     },
  //   },
  //   group_id: {
  //     handler: function(newVal, oldVal) {
  //       if (this.rankName == "team_rank") {
  //         //拿到分组信息
  //         this.new_group_id = newVal;
  //         //传给父组件
  //         this.$emit("update:group_id", this.new_group_id);
  //         //更新页码，初始化
  //         this.pager.pageNum = 1;
  //         this.handleQuery();
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
  data() {
    return {
      season_id: "",

      ids: [],
      type_id: "s1",
      loading: false,
      pageSize: 15,
      scoreChangeVisible: false,
      scoreChangeData: {
        team_id: "",
        sect_id: "",
        sect_name: "",
        score: "",
        new_score: "",
      },
      sortChangeVisible: false,
      sortChangeData: {
        team_id: "",
        sect_id: "",
        sect_name: "",
        sort: "",
      },
      searchForm: {
        sect_id: "",
        sect_name: "",
      },
      dataList: [
        {
          prop: "rank",
          label: "排名",
        },
        {
          prop: "sect_id",
          label: "门派ID",
        },
        {
          prop: "sect_name",
          label: "门派名称",
        },

        {
          prop: "sessionCount",
          label: "场次",
        },
        {
          prop: "win_fail",
          label: "胜负",
          width: 100,
        },
        {
          prop: "member_mvp_score",
          label: "MVP",
          width: 100,
        },
        {
          prop: "member_svp_score",
          label: "SVP",
          width: 100,
        },
        {
          prop: "wolf_win",
          label: "狼人胜场",
          width: 100,
        },
        {
          prop: "god_win",
          label: "神职胜场",
          width: 100,
        },
        {
          prop: "civilian_win",
          label: "平民胜场",
          width: 100,
        },

        {
          prop: "sect_type",
          label: "门派类型",
          width: 120,
          formatter: (row) => {
            switch (row.sect_type) {
              case 0:
                return "替补门派";
              case 1:
                return "正赛门派";
              default:
                return "";
            }
          },
        },
        {
          prop: "score",
          label: "积分",
        },

        {
          prop: "sort",
          label: "同分排序",
        },
      ],
    };
  },
  mounted() {
    this.type_id = this.$route.query.type_id;
    this.match_id = this.$route.query.match_id;
    this.season_id = this.$route.query.season_id;
  },
  methods: {
    scoreInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (!/^\d+(\.\d{1,2})?$/.test(value)) {
        callback(new Error("请输入大于等于0的整数或1位小数"));
      } else {
        callback();
      }
    },
    sortInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入整数"));
      }

      if (
        Number.isInteger(Number(value)) &&
        Number(value) >= 0 &&
        Number(value) < 10000
      ) {
        callback();
      } else {
        callback(new Error("请输入0-9999之间的整数"));
      }

      callback();
    },
    async handleChangeScore(row) {
      this.scoreChangeData.sect_id = row.sect_id;
      this.scoreChangeData.sect_name = row.sect_name;
      this.scoreChangeData.score = row.score;
      this.scoreChangeData.new_score = row.score;
      this.scoreChangeData.team_id = row.team_id;
      this.scoreChangeVisible = true;
    },
    async handleChangeSort(row) {
      this.sortChangeData.sect_id = row.sect_id;
      this.sortChangeData.sect_name = row.sect_name;
      this.sortChangeData.sort = row.sort;
      this.sortChangeData.team_id = row.team_id;
      this.sortChangeVisible = true;
    },

    async saveScore() {
      this.$refs.scoreForm.validate(async (valid) => {
        if (valid) {
          let temp = {
            match_id: this.match_id,
            // stage: this.stage,
            team_id: this.scoreChangeData.team_id,
            score: this.scoreChangeData.new_score + "",
            type_id: this.type_id,
          };

          if (this.season_id != "") {
            temp["season_id"] = this.season_id;
          }
          if (
            this.$route.query.match_type == "match_s1" ||
            this.$route.query.match_type == "match_s2"
          ) {
            //这是以前的逻辑，是为了区分预选赛和联赛
            if (
              this.$route.query.type_id == "s1" ||
              this.$route.query.type_id == "s2"
            ) {
              temp["matchType"] = "";
            } else {
              temp["matchType"] = "league";
            }
            let { data, errorCode } =
              await this.$http.match.stage_change_team_rank(temp);

            if (errorCode != "0000") {
              return;
            }

            this.$message.success("修改成功");
            // this.handleQuery();

            this.scoreChangeVisible = false;
            this.$emit("updateQuery");
          } else {
            let { data, errorCode } =
              await this.$http.match_subject.new_stage_change_team_rank(temp);

            if (errorCode != "0000") {
              return;
            }

            this.$message.success("修改成功");
            // this.handleQuery();

            this.scoreChangeVisible = false;
            this.$emit("updateQuery");
          }
        }
      });
    },
    saveSort() {
      this.$refs.sortForm.validate(async (valid) => {
        if (valid) {
          let temp = {
            match_id: this.match_id,
            team_id: this.sortChangeData.team_id,
            sort: this.sortChangeData.sort + "",
            type_id: this.type_id,
          };
          // S4_stage_change_team_sort
          if (this.season_id != "") {
            temp["season_id"] = this.season_id;
          }

          if (
            this.$route.query.match_type == "match_s1" ||
            this.$route.query.match_type == "match_s2"
          ) {
            //这是以前的逻辑，是为了区分预选赛和联赛
            if (
              this.$route.query.type_id == "s1" ||
              this.$route.query.type_id == "s2"
            ) {
              temp["matchType"] = "";
            } else {
              temp["matchType"] = "league";
            }
            let { data, errorCode } =
              await this.$http.match.stage_change_team_sort(temp);

            if (errorCode != "0000") {
              return;
            }

            this.$message.success("修改成功");

            this.sortChangeVisible = false;
            this.$emit("updateQuery");
          } else {
            let { data, errorCode } =
              await this.$http.match_subject.S4_stage_change_team_sort(temp);

            if (errorCode != "0000") {
              return;
            }

            this.$message.success("修改成功");

            this.sortChangeVisible = false;
            this.$emit("updateQuery");
          }
        }
      });
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px !important;
  height: 300px !important;
  line-height: 300px !important;
  text-align: center;
}
/deep/ .el-upload-dragger {
  width: 300px !important;
  height: 300px !important;
}
.avatar {
  width: 300px !important;
  height: 300px !important;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
  width: 300px !important;
  height: 300px !important;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}
</style>
